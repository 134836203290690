export default {
  account: {
    orders: "Zamówienia",
    setting: "Ustawienia konta",
    pwz: "pwz",
    personal_data: "Dane",
    address: "Twój adres",
    addresses: "twoje adresy",
    addresses_and_invoices: "Adresy i faktury",
    collection_points: "Twoje Punkty Pobrań",
    childrens: "Twoje dzieci",
    email: "Adres e-mail",
    password: "Hasło",
    current_password: "Aktualne hasło",
    new_password: "Nowe hasło",
    confirm_password: "Powtórz nowe hasło",
    update_data: "Edytuj swoje dane",
    change_password: "Zmień swoje hasło",
    blocked: "Zostałeś zablokowany",
    contact_administrator: "Skontaktuj się z administratorem",
    children: "Dzieci przypisane do Twojego konta ",
    welcome: "Witaj",
    floor: "Piętro",
    gate_code: "Kod do bramy",
    additional_info: "Dodatkowe informacje",
    default_address: "Domyślny adres",
    remove_address_title: "Na pewno chcesz usunąć adres?",
    add_new_address: "Dodaj nowy adres",
    add_new_invoice_data: "Dodaj nowe dane do faktury",
    add_invoice_data: "Dodaj nowe dane do faktury",
    add_new_collection_point: "Dodaj nowy Punkt Pobrań",
    edit_address: "Edytuj adres",
    new_address: "Nowy adres",
    edit_invoice_data: "Edytuj dane do faktury",
    no_saved_addresses: "Brak zapisanych adresów",
    no_saved_invoices_data: "Brak zapisanych danych do faktury",
    check_your_address_is_correct: "Sprawdź, czy poniższy adres jest poprawny.",
    no_assigned_children: "Brak przypisanych dzieci",
    patient_data_summary: "Podsumowanie danych osoby zamawiającej badania",
    ask_invoice_data: "Poproś o wystawienie faktury",
    asked_invoice_data: "Wysłałeś prośbę o wystawienie faktury",
    download_invoice: "Pobierz fakturę",
    doctor_consents: "Zgody udzielone dla lekarza",
    your_consents: "Zgody",
    share_examinations_results:
      "Udziel zgody na dostęp do Twoich wyników badań",
    doctor_consents_label: "Zgoda na dostęp do wyników badań",
    doctor_label: "Lekarz",
    no_assigned_consents: "Brak próśb o dostęp do Twoich wyników badań",
    doctor_short: "Dr.",
    remove_invoice_data_title: "Na pewno chcesz usunąć dane do faktury?",
    new_invoice_data: "Nowe dane do faktury",
    remove_facility_alert: "Chcesz usunąć Punkt Pobrań?",
    doctor_consent_content:
      "wysłał prośbę o dostęp do historii Twoich wyników badań. Odpowiedz wybierając jedną z poniższych opcji.",
    doctor_consent_title: "Prośba o dostęp do wyników badań.",
    change_phone_number_modal_title: "Zmiana numeru telefonu",
    change_phone_number_modal_description:
      "Poniżej wpisz kod który wysłaliśmy na Twój nowy numer telefonu.",
    change_status: "Zmiana statusu konta",
    delete_account: "Usuń konto",
    delete_account_consent_text_1:
      "Usunięcie konta – wiąże się z zakończeniem korzystania z usługi. W związku z czym historia zleceń i wyników nie będzie dostępna na tym portalu. Zaprzestanie korzystania z usługi nie oznacza usunięcia przechowywanych przez usługodawcę wyników badań. Wyniki badań laboratoryjnych wykonanych przez ALAB laboratoria Sp. z o.o. są przechowywane zgodnie z ustawą z dnia 6 listopada 2018 r. o prawach pacjenta i Rzeczniku Praw Pacjenta. Możesz uzyskać do nich dostęp poprzez stronę",
    delete_account_consent_text_2:
      "i zakładkę „Odbierz wyniki” – bez konieczności logowania lub w Punkcie Pobrań ALAB laboratoria.",
    delete_account_contact_1:
      "W przypadku pytań zachęcamy do kontaktu z infolinią ALAB laboratoria dostępną pod numerem telefonu",
    delete_account_contact_2:
      "lub mailowo poprzez formularz kontaktowy dostępny na stronie",
    delete_account_consent_text:
      "Potwierdzam zapoznanie się z powyższą informacją",
    declarations: "Oświadczenia",
    legal_guardian_of_this_minor_declaration:
      "Oświadczam, że jestem opiekunem prawnym tej osoby nieletniej",
  },
  block_account: {
    your_account_has_been_blocked:
      "Po zamknięciu komunikatu nastąpi automatyczne wylogowanie z konta. W celu odblokowania go - skontaktuj się z działem obsługi klienta pod numerem",
  },

  action: {
    change: "Zmień",
    see_details: "Zobacz szczegóły",
    see_doctor: "Zobacz lekarza",
    see_referral: "Zobacz zlecenie",
    go_back: "Wróć",
    save_changes: "Zapisz zmiany",
    choose: "Wybierz",
    chosen: "Wybrano",
    choose_facility: "Wybierz Punkt Pobrań",
    continue: "Kontynuuj",
    save: "Zapisz",
    clear: "Wyczyść",
    return: "Powrót",
    filter: "Filtruj",
    withdraw_funds: "Wypłać środki",
    edit: "Edytuj",
    download: "Pobierz",
    cancel: "Anuluj",
    confirm: "Potwierdź",
    assign: "Przypisz",
    search: "Szukaj",
    show_more: "Zobacz więcej",
    add: "Dodaj",
    preview: "Podgląd",
    pay_for_examinations: "Opłać badania",
    save_new_address: "Zapisz nowy adres",
    save_new_invoice_data: "Zapisz dane do faktury",
    approve: "Zatwierdź",
    accept: "Zaakceptuj",
    reject: "Odrzuć",
    expand: "rozwiń",
    collapse: "zwiń",
    download_exam_results: "Pobierz wyniki badań",
    download_results: "Pobierz wyniki",
  },

  auth: {
    dont_have_a_patient_account_create_one:
      "Nie masz Konta Pacjenta? Załóż je!",
    your_benefits: "Twoje korzyści",
    benefits: {
      benefit_one: "Archiwum wyników badań",
      benefit_two: "Pomoc w wyborze badań",
      benefit_three: "Interpretacja wyników",
      benefit_four: "Konsultacje lekarskie i dietetyczne",
      benefit_fife: "Akcje profilaktyczne",
    },
    purchase_without_registration: "Zakup bez rejestracji",
    buy_as_a_guest: "Kup jako gość bez zakładania konta",
    login_on_patient_account: "Zaloguj się na Konto Pacjenta",
    create_account: "Załóż konto",
    enter_code: "Wpisz kod, który otrzymałeś w wiadomości SMS",
    enter_sms_code: "Wpisz kod, który otrzymałeś w wiadomości SMS",
    congratulations: "Gratulacje!",
    account_succesfull_created: "Właśnie utworzyliśmy Twoje konto w ALAB",
    email: "Adres e-mail",
    first_name: "Imię",
    go_back_to_login: "Wróć do logowania",
    have_account: "Masz już konto?",
    last_name: "Nazwisko",
    full_name: "Imię i nazwisko",
    logout: "Wyloguj",
    not_have_account: "Nie masz jeszcze konta?",
    not_have_account_create: "Nie masz Konta Pacjenta? Załóż je!",
    not_receive_code: "Kod do mnie nie dotarł",
    not_remember_password: "Nie pamiętasz hasła?",
    password: "Hasło",
    password_confirm: "Powtórz hasło",
    password_remind: "Przypomnij hasło",
    password_reset: "Resetuj hasło",
    password_update: "Zmień hasło",
    phone: "Numer telefonu",
    pwz: "Nr PWZ",
    register: "Zarejestruj się",
    go_to_registration: "Przejdź do rejestracji",
    reset: "Nadaj nowe hasło do swojego konta w portalu",
    send_reset_link: "Na podany adres e-mail wyślemy link resetujący hasło",
    sent_reset_link: "Wysłaliśmy Ci SMS z kodem weryfikacyjnym pod numer",
    sent_reset_link_email:
      "Jeżeli podany adres e-mail istnieje w naszej bazie, to za chwilę otrzymasz na niego link do resetowania hasła.",
    password_reset_link_sended:
      "Wysłaliśmy na Twój adres e-mail link do resetowania hasła",
    sent_reset_link_error:
      "Link został wysłany, odczekaj chwilę aby otrzymać nowy link.",
    reset_link_expired: "Twój link do resetu hasła wygasł",
    sign_in: "Zaloguj się",
    sign_up: "Zarejestruj się",
    enter_your_phone_number: "Wpisz swój numer telefonu",
    enter_your_pesel_number: "Podaj numer PESEL",
    enter_birthday: "Podaj datę urodzenia",
    processing_accept:
      "Wyrażam zgodę na przetwarzanie moich danych osobowych, tj. adresu e-mail, w celu przesyłania newslettera drogą elektroniczną przez ALAB laboratoria Sp. z o. o. ul. Stępińska 22 / 30, 00 − 739 Warszawa, zgodnie z art. 10 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (t. j. Dz. U. z 2017 r. poz. 1219). Oświadczam, iż zostałam/em poinformowany o moich uprawnieniach, o których szerzej mogę przeczytać w",
    processing_accept_2: "obowiązku informacyjnym",
    processing_accept_3:
      "https://www.alablaboratoria.pl/19711-ochrona-danych-osobowych",
    marketing_accept:
      "Wyrażam zgodę na kontaktowanie się ze mną przez ALAB laboratoria Sp. z o.o. z siedzibą w Warszawie, przy ul. Stępińska 22/30 ,00-739 Warszawa, w celu przedstawienia informacji handlowej za pośrednictwem telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących zgodnie z art. 172 ustawy z dnia 16 lipca 2004 r. - Prawo telekomunikacyjne (t.j. Dz. U. z 2017 r. poz. 1907 z późn. zm.), czyli m.in. za pomocą telefonu komórkowego. Moja zgoda może zostać odwołana w każdym czasie.",
    accept_terms_1:
      "Wyrażam zgodę na przetwarzanie moich danych osobowych wskazanych w powyższym formularzu w celu założenia Konta Pacjenta. Szerzej na temat przetwarzania danych osobowych przeczytasz w",
    accept_terms_2: "Obowiązku informacyjnym",
    accept_terms_3: "https://www.alab.pl/static/regulamin",
    accept_terms_4: "Politykę Prywatności",
    sms: "Kod SMS",
    specialist_panel: "do panelu specjalisty",
    patient_panel: "na Konto Pacjenta",
    patient_panel_login_title: "Konto Pacjenta",
    patient_panel_login_description:
      "Jeśli posiadasz konto w ALAB, wprowadź swój e-mail i hasło, aby zalogować się do konta.",
    verify: "Weryfikuj",
    pay_for_examinations: "aby opłacić badania",
    enter_email_and_password: "Wpisz swój e-mail i hasło,",
    to_create_account: "aby utworzyć konto w portalu",
    enter_phone_number: "Wpisz swój numer telefonu",
    enter_pesel: "Wpisz swój pesel",
    login_by_pesel: "Zarejestruj się używając pesel",
    login_by_phone: "Zarejestruj się używając numeru telefonu",
    identity_card: "Nr dowodu",
    pesel: "Pesel",
    current_password: "Obecne hasło",
    set_password: "Ustaw hasło",
    salary: "Wynagrodzenie",
    patient_account: "do Konta Pacjenta",
    or: "lub",
    to_pay_for_examinations: "aby zapłacić za badania",
    single_order: "Pojedyncze zlecenie",
    enter_pesel_and_barcode:
      "Wprowadź PESEL oraz kod kreskowy zamówienia, aby otrzymać dostęp do wyników badań",
    attention_qualified_electronic_signature_1:
      "<b>UWAGA:</b> Wyniki badań laboratoryjnych wykonywane w laboratoriach sieci grupy ALAB i wydawane w formie elektronicznej <b>podpisane są kwalifikowanym podpisem elektronicznym</b>, co oznacza, że stanowią <b>pełnoprawne wyniki badań.</b>",
    attention_qualified_electronic_signature_2:
      "Informujemy również, że wyniki badań podstawowych w laboratoriach ALAB są wydawane przez diagnostów laboratoryjnych przez całą dobę. Dlatego też najkrótszy przewidywany czas oczekiwania na wyniki w trybie rutynowym to 1 dzień roboczy, czyli minimum 24 godziny od pobrania materiału. Jeśli sprawdzacie Państwo swoje wyniki i widzicie pliki PDF z częściowym zakresem badań, oznacza to, że proces diagnostyczny wykonania badań jeszcze się nie zakończył, należy wtedy odczekać do upływu 24 godzin od pobrania materiału.Wszystkie czasy oczekiwań na badania obowiązujące w ALAB laboratoria podawane są w dniach roboczych (nie obejmują dni wolnych od pracy).",
    attention_qualified_electronic_signature_3:
      "W przypadku badań wykonywanych poza siecią ALAB, wynik on-line jest dostępny w formie skanu. Oryginalne dokumenty wyników takich badań należy odebrać w punkcie pobrań.",
    where_to_find_barcode: "Gdzie znajdę kod kreskowy?",
    barcode_is_on_confirmation:
      "Kod kreskowy znajduje się na potwierdzeniu realizacji usługi oraz na kartoniku odbioru wyników, który otrzymałeś w Punkcie Pobrań",
    barcode: "Kod kreskowy",
    type_only_digits: "Wpisz same cyfry, bez liter",
    details_of_your_test_results_are_below:
      "Szczegóły Twoich wyników badań znajdują się poniżej.",
    results_can_be_presented_on_one_document:
      "Wyniki badań realizowane w ciągu 1 dnia roboczego mogą być prezentowan na jednym zbiorczym dokumencie lub kilku oddzielnych.",
    not_found_referral:
      "Nie znaleziono skierowania dla podanych danych. Spróbuj ponownie.",
    checkboxes: {
      agree: "Zgadzam się z",
      marketing:
        "Zgadzam się na wykorzystywanie moich danych osobowych w celach marketingowych",
      terms: "Regulaminem",
      service: "serwisu",
      and: "i",
      privacy_policy: "Polityką prywatności",
      no_pesel_number: "Brak numeru PESEL",
      without_register: "Chcę dokonać zakupu bez zakładania konta",
      accept_terms1: "Wyrażam zgodę na przetwarzanie moich danych osobowych",
      accept_terms1_expanded:
        "Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w powyższym formularzu w celu zakupu badania.",
      accept_terms1_link: "Obowiązek informacyjny",
      accept_terms2: "Zapoznałam/em się i akceptuję Regulamin sklepu.",
      accept_terms2_expanded: "Zapoznałam/em się i akceptuję",
      accept_terms2_link: "Regulamin sklepu",
    },
    commission_options: {
      d15p0: "15% dla lekarza, 0% dla Pacjenta",
      d10p5: "10% dla lekarza, 5% dla Pacjenta",
      d5p10: "5% dla lekarza, 10% dla Pacjenta",
      d0p15: "0% dla lekarza, 15% dla Pacjenta",
      d5p5: "5% dla lekarza, 5% dla Pacjenta",
    },
    birth_date: "Data urodzenia",
    day: "Dzień",
    month: "Miesiąc",
    year: "Rok",
    gender: {
      title: "Płeć",
      m: "Mężczyzna",
      f: "Kobieta",
    },
    services: {
      comprehensive_medical_services: "Twoje korzyści",
      archive: "Archiwum wyników badań",
      help: "Pomoc w wyborze badań",
      interpretation: "Interpretacja wyników",
      consultation: "Konsultacje lekarskie i dietetyczne",
      promos: "Akcje profilaktyczne",
    },
  },

  cart: {
    selected_examinations: "Wybrane badania",
    no_examinations: "Nie wybrałeś jeszcze żadnych badań do zlecenia.",
    nurse_service: "Usługa pielęgniarska",
    covid_service: "Usługa pielęgniarska COVID",
    total: "Łącznie",
    your_commission: "Twoje wynagrodzenie",
    your_discount: "Zniżka",
    issue_referral: "Wystaw zlecenie",
    edit_referral: "Edytuj zlecenie",
    edit_referral_warning:
      "Edycja tego zlecenie spowoduje wystawienie nowego zlecenia i anulowanie obecnego zlecenia.",
    pay_referral: "Zapłać",
    details: "Szczegóły",
    price_for_commuting_service: "Mobilna usługa pielęgniarska",
    pay_only_once: "Płacisz tylko raz",
    your_examinations: "Twoje badania",
    pay_for_examinations: "Zamów i zapłać",
    go_to_summary: "Podsumowanie",
    other_adults_test_results:
      "Pozostałe osoby dorosłe odbiorą wyniki badań, logując się na stronie",
    add_more_patients: "Dodaj kolejnych Pacjentów",
    add_child: "Dodaj dziecko",
    attention: "Uwaga",
    loss_of_already_accrued_discounts_alert:
      "Usunięcie badania spowoduje stracenie już naliczonych zniżek. Czy na pewno chcesz to zrobić?",
    tests_unavailable_at_the_selected_collection_point:
      "Badania niedostępne w wybranym Punkcie Pobrań.",
    please_select_a_different_collection_point: "Zmień",
    savings: "Oszczędzasz",
    confirm_remove_examination: "Czy na pewno chcesz usunąć badanie?",
    confirm_remove_examination_discount:
      "Spowoduje to usunięcie zastosowanych kodów rabatowych oraz naliczonych zniżek.",
    next_step: "Dalej",
    check_all: "Sprawdź wszystkie",
  },

  checkout: {
    price: "Cena",
    net: "Cena netto",
    gross: "Cena brutto",
    vat: "VAT",
    after_discount: "Cena&nbsp;po zniżce",
    chose_collection_point: "Wybierz Punkt Pobrań",
    chose_collection_point_description:
      "Aby dokończyć proces zamówienia wymagane jest wybranie Punktu Pobrań.",
    chose_another_address: "Wybierz inny adres",
    chose_another_invoice_data: "Wybierz inne dane",
    no_default_address: "Brak domyślnego adresu",
    saved_addresses: "Zapisane adresy",
    saved_invoices_data: "Zapisane dane do faktury",
    cart_is_empty: "Nie posiadasz żadnych dodanych badań",
    see_examinations_catalog: "Zobacz Katalog badań",
    summary: "Podsumowanie",
    address_details: "Dane adresowe",
    provide_contact_information:
      "Podaj dane kontaktowe osoby zamawiającej badania",
    enter_data: "Wprowadź dane",
    visit_address: "Adres wizyty",
    confirm_collection_address: "Potwierdzam Punkt Pobrań",
    confirm_collection_address_home: "Potwierdzam adres pobrania",
    collection_address: "Adres pobrania",
    your_collection_address: "Twój wybrany Punkt Pobrań",
    home_service_address: "Adres wizyty domowej",
    data: "Dane",
    additional_information: "Dodatkowe informacje",
    street: "Ulica",
    home_number: "Nr domu",
    premises_number: "Nr lokalu",
    zip_code: "Kod pocztowy",
    city: "Miejscowość",
    level: "Piętro",
    gate_code: "Kod do bramy",
    additional_comments: "Dodatkowe uwagi",
    additional_comments_invoice: "Dodatkowe adnotacje (widoczne na fakturze)",
    enter_the_code_for_the_gate:
      "Podaj kod do bramy, w przypadku, gdy nie ma możliwości, by otworzył ją Pacjent bądź domownik",
    set_up_an_account_and_buy_examinations_faster:
      "Chcę założyć konto i szybciej kupować badania",
    it_looks_like_you_already_have_an_account_in_our_store:
      "Wygląda na to, że masz już konto w naszym sklepie. Zaloguj się, żeby automatycznie uzupełnić dane adresowe.",
    main_page: "Strona główna",
    remember_when_forget:
      "Jeśli zapomnisz o jakimś badaniu, będziesz mógł dokupić je na miejscu",
    your_location_is_not_yet_available_for_home_visits:
      "W Twojej lokalizacji nie są jeszcze dostępne wizyty domowe. Możesz wykonać badania w jednym z naszych Punktów Pobrań.",
    added_patients: "Dodani pacjenci",
    ordered_tests: "Zamówione badania",
    ordered_tests_short: "Badania",
    date_of_the_visit: "Termin mobilnej usługi pielęgniarskiej",
    choose_a_date_for_your_home_visit:
      "Wybierz termin mobilnej usługi pielęgniarskiej",
    entire_order:
      "Realizacja całości zamówienia musi odbyć się podczas jednej wizyty. Jeśli chcesz wykonać poszczególne badania w różnych terminach, prosimy o złożenie na nie osobnych zamówień.",
    choose_a_date: "Wybierz termin",
    payment_method: "Metoda płatności",
    choose_payment_method_for_examinations:
      "Wybierz formę płatności za badania",
    choose_payment_method: "Wybierz metodę płatności",
    fast_transfer: "Szybki przelew",
    credit_card: "Karta płatnicza",
    name_and_surname: "Imię i nazwisko na karcie",
    card_number: "Numer karty płatniczej",
    valid_to: "Ważna do",
    card_code: "Kod CVV2/CVC2",
    where_can_i_find_the_code: "Gdzie znajdę kod?",
    three_digits:
      "Kod CVV2/CVC2 znajdziesz na odwrocie karty płatniczej - są to 3 cyfry.",
    no_examination_at_selected_point_info:
      "Badanie niedostępne w wybranym Punkcie Pobrań",
    home_service: "ALAB w domu",
    collection_at_point: "Punkt Pobrań",
    selected_collection_point: "Wybrany Punkt Pobrań",
    chosen_date: "Wybrany termin:",
    reservation_info_alert:
      "Wybrany termin jest zarezerwowany na 15 minut od momentu wyboru",
    reservation_zip_code_error_alert:
      "Wprowadony kod pocztowy jest niepoprawny",
    srvice_address: "Adres usługi",
    choose_one_of_our_ALAB_points:
      "Wybierz jeden z naszych {number} punktów ALAB aby wykonać badania.",
    last_form: "Ostatnie formalności",
    accept_terms_1: "Oświadczam, iż zapoznałam/em się i akceptuję",
    accept_terms_1_2:
      "Regulamin zawierania drogą elektroniczną umów o udzielanie świadczeń zdrowotnych",
    accept_terms_1_3: "https://www.alab.pl/static/regulamin",
    accept_terms_2:
      "Niniejszym oświadczam, iż zlecane przeze mnie badania laboratoryjne są wykonywane w celu zapewnienia osobie korzystającej z badań opieki medycznej w zakresie profilaktyki zdrowia, zachowaniu zdrowia, ratowaniu zdrowia, przywracaniu i poprawie zdrowia. Akceptuję metody wykonywania badań stosowane w laboratorium oraz wybranego z aktualnego rejestru podwykonawcę. Ponadto oświadczam, iż zlecone badania nie będą wykorzystywane w celach związanych z opieką medyczną w zakresie:",
    accept_terms_2_2:
      "1.   testów genetycznych mających na celu ustalenie ojcostwa lub identyfikacji zwłok.",
    accept_terms_2_3:
      "2.   badań, diagnozy, opinii albo czynności wykonywanych do celów medycyny sądowej.",
    accept_terms_2_4:
      "3.   badań w celu uzyskania renty inwalidzkiej czy dla celów ubiegania się o polisę ubezpieczeniową.",
    accept_terms_2_5:
      "4.   chirurgii plastycznej i innych zabiegów kosmetycznych, nie służącym przywracaniu lub poprawie zdrowia.",
    accept_terms_2_6:
      "5.   bankowania krwi pępowinowej i komórek macierzystych.",
    accept_terms_3:
      "Oświadczam, że wyrażam zgodę na wykonanie przez ALAB laboratoria sp. z o.o. z siedzibą w Warszawie usługi wynikającej z umowy zawartej na odległość z ALAB laboratoria sp. z o.o. z siedzibą w Warszawie przed upływem 14 dniowego terminu na odstąpienie od zawartej umowy.",
    accept_terms_4:
      "Oświadczam, że zostałem/łam poinformowany/a i przyjmuję do wiadomości, że z chwilą wykonania w pełni usługi i spełnienia przez ALAB laboratoria sp. z o.o. z siedzibą w Warszawie świadczenia wynikającego z umowy utracę prawo do odstąpienia od umowy.",
    accept_terms_5:
      "Oświadczam, że zostałem/łam poinformowany/a, że moja zgoda na wykonania usługi przez ALAB laboratoria sp. z o.o. z siedzibą w Warszawie przed upływem 14 dniowego terminu na odstąpienie od umowy rodzi obowiązek zapłaty wynagrodzenia za świadczenie spełnione do chwili odstąpienia od umowy, zgodnie z art. 35 ustawy z dnia 30 maja 2014 r. o prawach konsumenta.",
    is_order_patient: "Pacjent to osoba zamawiająca badania",
    collection_point_is_no_longer_active:
      "Wybrany Punkt Pobrań przestał być aktywny.",
    change_the_collection_point: "Zmień Punkt Pobrań",
    child_contact_facility:
      "Aby zapewnić maksymalny komfort małemu Pacjentowi, zachęcamy, by po zakupie potwierdzić telefonicznie termin wizyty.",
  },

  collection_facility: {
    collection_facility_title:
      "W Twojej lokalizacji nie są jeszcze dostępne wizyty domowe. Możesz wykonać badania w jednym z naszych Punktów Pobrań.",
    collection_facility_text:
      "Ustaw dla którego Punktu Pobrań chcesz mieć pokazywany cennik badań laboratoryjnych. będziesz mógł go zmienić w każdej chwili w swoim profilu.",
    search_placeholder: "Miasto, ulica lub kod pocztowy",
    search_placeholder_no_code: "Wpisz miasto lub ulicę",
    current_location_error:
      "Nie pozwolileś przeglądarce na pobranie lokalizacji. Spróbuj ponownie.",
    hide_list: "Ukryj listę",
    show_list: "Pokaż listę",
    opening_hours: "Godziny otwarcia",
    default_point: "Domyślny Punkt Pobrań",
    no_assigned_collection_points: "Brak zapisanych Punktów Pobrań",
    show_filters: "Pokaż filtry",
    hide_filters: "Ukryj filtry",
    show_on_map: "Pokaż na mapie",
    set_the_route: "Wyznacz trasę",
    selected: "Wybrany",
    only_in_point: "Sprzedaż w Punkcie Pobrań",
  },

  dashboard: {
    inaccessible_facility:
      "Twój Punkt Pobrań jest nieaktywny bądź nie można w nim wykonać badań.",
    inaccessible_facility_btn: "Zmień Punkt Pobrań",
    commission_info:
      "Wynagrodzenie jest sumą kwot należnych do wypłaty za zlecenia zrealizowane",
  },

  doctor_status: {
    blocked: "Zablokowany",
    active: "Aktywny",
    inactive: "Nieaktywny",
    all: "Wszystkie",
  },

  empty_list: {
    referrals: "Lista zleceń jest pusta.",
    doctors: "Lista lekarzy jest pusta.",
    patients: "Lista Pacjentów jest pusta.",
    invoices: "Lista faktur jest pusta.",
  },

  error: {
    accept_processing: {
      accepted: "Zgoda jest wymagana",
    },
    action_attempts: {
      user: {
        sms: {
          per_day: "Przekroczono limit dziennej ilości prób",
          per_hour: "Przekroczono limit godzinnej ilości prób",
        },
      },
    },
    without_register: {
      user_not_logged_in: "Użytkownik nie jest zalogowany",
    },
    discount_code: {
      selected_invalid: "Podany kod nie istnieje.",
      expired: "Kod nieaktywny",
      used: "Podany kod został już wykorzystany",
      minimum_order_amount: "Minimalna kwota zamówienia nie została spełniona",
      requirements: "Kod nie dotyczy wybranych badań",
      for_own_package: "Kod nie dotyczy zestawu własnego",
    },
    accept_data_processing: {
      accepted: "Zgoda jest wymagana",
      checked: "Zgoda jest wymagana",
    },
    g_recaptcha_response: {
      required: "Potwierdzenie nie jest prawidłowe",
      recaptcha: "Potwierdzenie nie jest prawidłowe",
    },
    auth: {
      blocked: "Konto zostało zablokowane. Skontaktuj sie z administratorem",
      age_not_allowed: "Użytkownik ma mniej niż 18 lat",
      age_not_a_child: "Użytkownik ma więcej niż 18 lat",
    },
    name: {
      required: "Imie i nazwisko jest wymagane",
    },
    sex: {
      required: "Płeć jest wymagana",
    },
    gender: {
      required: "Płeć jest wymagana",
      selected_invalid: "Płeć jest wymagana",
      required_if_empty: {
        pesel: "Płeć jest wymagana",
      },
    },
    accept_terms: {
      accepted: "Zgoda jest wymagana",
      checked: "Zgoda jest wymagana",
    },
    accept_marketing: {
      accepted: "Zgoda jest wymagana",
    },
    code: {
      required: "Kod sms jest wymagany",
      incorrect: "Podany kod jest nieprawidłowy",
    },
    gate_code: {
      must_not_be_greater_than_20: "Max. 20 znaków",
    },
    email: {
      already_taken: "Podany e-mail został już wykorzystany",
      credentials_not_match: "Dane uwierzytelniające są nieprawidłowe",
      email: "Adres e-mail jest niepoprawny",
      required: "E-mail jest wymagany",
      selected_invalid: "Podany e-mail nie istnieje w bazie",
      require_authenticated: "Użytkowninik musi być zalogowany",
    },
    barcode: {
      required: "Wpisany kod kreskowy jest wymagany",
      numeric: "Wpisany kod kreskowy musi być liczbą",
      digits: "Wpisany kod kreskowy powinien mieć 10 cyf",
      not_found: "Nie znaleziono wyników dla podanych danych",
      already_exists: "Posiadasz odebrane wyniki dla podanego kodu kreskowego",
      digits_length_in: {
        "8_10": "Kod kreskowy może zawierać 8 lub 10 znaków",
      },
    },
    pesel: {
      required: "Pesel jest wymagany",
      already_taken: "Podany pesel został już wykorzystany",
      digits: "Pesel musi mieć długość 11",
      numeric: "Pesel musi być liczbą",
      invalid: "Niepoprawny numer pesel",
      invalid_date: "Niepoprawny numer pesel",
      required_if_empty: {
        birth_date: "Pesel lub data urodzenia jest wymagana",
        is_no_pesel: "Pesel lub data urodzenia jest wymagana",
      },
      required_when_not_present: {
        phone_number: "Numer telefonu lub pesel jest wymagany",
      },
      child_incorrect: "Pesel dotyczy dziecka pomiędzy 5 a 17 rokiem życia",
      child_invalid: "Nie można edytować edytować",
      incorrect: "Nie możemy odnaleźć użytkownika o podanym peselu",
      birth_date_diffrent: "Data urodzenia oraz pesel różnią się od siebie",
      distinct:
        "Ten sam numer pesel powtarza się u innych Pacjentów w zamówieniu",
    },
    birth_date: {
      required: "Data urodzenia jest wymagana",
      required_if_empty: {
        pesel: "Pesel lub data urodzenia jest wymagana",
        is_no_pesel: "Pesel lub data urodzenia jest wymagana",
      },
      must_be_date_after_or_equal:
        "Data urodzenia musi być większa lub równa dacie 01.01.1900",
      must_be_a_date_before: "Nie można wybrać takiej daty urodzenia",
      invalid_date: "Data urodzenia nie jest poprawną datą",
      incorrect: "Data urodzenia oraz pesel różnią się od siebie",
      child_incorrect:
        "Data urodzenia dotyczy dziecka pomiędzy 5 a 17 rokiem życia",
    },
    first_name: {
      required: "Imię jest wymagane",
      required_if_empty: {
        individual: "Imię jest wymagane",
      },
    },
    last_name: {
      required: "Nazwisko jest wymagane",
      required_if_empty: {
        individual: "Nazwisko jest wymagane",
      },
    },
    main_medical_category_id: {
      required: "Proszę wybrać kategorie",
    },
    current_password: {
      required: "Obecne hasło jest wymagane",
      at_least_characters: "Obecne hasło musi mieć co najmniej 8 znaków",
      invalid_format:
        "Obecne hasło musi zawierać przynajmniej 8 znaków, jedną małą literę, jedną dużą literę i jedną cyfrę",
      incorrect: "Obecne hasło jest nieprawidłowe",
      is_required_when: {
        current_password: {
          is_present: "Aktualne hasło jest wymagane gdy wprowadzono nowe hasło",
        },
      },
    },
    password: {
      required: "Hasło jest wymagane",
      at_least_characters: "Hasło musi mieć co najmniej 8 znaków",
      invalid_format:
        "Hasło musi zawierać przynajmniej 8 znaków, jedną małą literę, jedną dużą literę i jedną cyfrę",
      current_password_must_be_different: "Nowe hasło musi być inne niż stare",
    },
    password_confirm: {
      required: "Potwierdzenie hasła jest wymagane",
      must_match: "Hasło i potwierdzenie hasła muszą być takie same",
      is_required_when: {
        current_password: {
          is_present:
            "Powtórz nowe hasło jest wymagane gdy wprowadzono nowe hasło",
        },
      },
    },
    phone_number: {
      already_taken: "Podany numer telefonu został już wykorzystany",
      required: "Nr telefonu jest wymagany",
      digits: "Nr telefonu musi mieć długość 9",
      incorrect: "Nie możemy odnaleźć użytkownika o podanym nr telefonu",
      required_when_not_present: {
        pesel: "Numer telefonu lub pesel jest wymagany",
      },
      distinct: "Podany numer telefonu został już wykorzystany",
      accepted: "Błędny numer telefonu",
    },
    phone_is_valid: {
      accepted: "Nr telefonu jest niepoprawny",
    },
    national_number: {
      required: "Nr telefonu jest wymagany",
    },
    national_phone_number: {
      required: "Nr telefonu jest wymagany",
    },
    pwz: {
      required: "Pwz jest wymagane",
    },
    company_name: {
      required: "Nazwa firmy jest wymagana",
      required_if_empty: {
        individual: "Nazwa firmy jest wymagana",
      },
    },
    nip: {
      required: "Nip jest wymagany",
      digits: "Nip musi mieć długość 10",
      required_if_empty: {
        individual: "Nip jest wymagany",
      },
    },
    street: {
      required: "Ulica jest wymagana",
    },
    house_number: {
      required: "Numer budynku jest wymagana",
    },
    home_number: {
      required: "Numer domu jest wymagany",
    },
    zip_code: {
      required: "Kod pocztowy jest wymagany",
      invalid: "Kod pocztowy jest niepoprawny",
    },
    postal_code: {
      required: "Kod pocztowy jest wymagany",
    },
    city: {
      required: "Miejscowość jest wymagana",
    },
    account_number: {
      required: "Numer rachunku bankowego jest wymagany",
      invalid: "Numer rachunku bankowego jest niepoprawny",
    },
    agree1: {
      accepted: "Zgoda jest wymagana",
    },
    identity_card: {
      required: "Nr dowodu jest wymagany",
    },
    title: {
      required: "Tytuł jest wymagany",
    },
    message: {
      required: "Treść wiadomości jest wymagana",
    },
    commission_option: {
      required: "Opcja wynagodzenia jest wymagana",
      selected_invalid: "Opcja wynagrodzenia musi być jedną z opcji powyżej",
    },
    is_verify: "Konto z podanym numerem telefonu już istnieje",
    reservation_date_not_available: "Wybrana godzina wizyty jest niedostępna",

    exist: {
      phone_number_exist: "Podany numer telefonu został już wykorzystany",
      pesel_exist: "Podany pesel został już wykorzystany",
      more_than_one_person_with_same_peseland_phone_number:
        "Podany pesel i nr tel. zostały już wykorzystane",
      person_same_pesel_in_database_with_different_phone_number:
        "Podany pesel został już wykorzystany",
      person_same_phone_number_in_database_with_different_pesel:
        "Podany numer telefonu został już wykorzystany",
      pesel_invalid: "Numer pesel jest nieprawidłowy",
      phone_number_invalid: "Numer telefonu jest nieprawidłowy",
    },
    pesel_invalid: "Podany pesel jest nieprawidłowy",
    address: {
      zip_code: {
        required_if_empty: {
          home_service: "Kod pocztowy jest wymagany",
        },
      },
      street: {
        required_if_empty: {
          home_service: "Ulica jest wymagna",
        },
      },
      home_number: {
        required_if_empty: {
          home_service: "Numer domu jest wymagany",
        },
      },
      city: {
        required_if_empty: {
          home_service: "Miasto jest wymagane",
        },
      },
    },
    acceptFacility: {
      required: "Potwierdzenie Punktu Pobrań jest wymagane",
    },
    acceptFacilityHome: {
      checked: "Potwierdzenie Adresu pobrania jest wymagane",
    },
    update: {
      pesel_invalid: "Pesel nie może zostać edytowany",
    },
    accept_terms_1: {
      required: "Zgoda jest wymagana",
    },
    accept_terms_2: {
      required: "Zgoda jest wymagana",
    },
    accept_terms_3: {
      required: "Zgoda jest wymagana",
    },
    accept_terms_4: {
      required: "Zgoda jest wymagana",
    },
    accept_terms_5: {
      required: "Zgoda jest wymagana",
    },
    age: {
      required_one_adult: "Wymagany jest co najmniej jeden dorosły",
    },
    consent: {
      accepted: "Zgoda jest wymagana",
    },
  },

  error_page: {
    ups: "Ups, coś poszło nie tak. Poczekaj chwilę lub spróbuj odświeżyć stronę.",
    refresh: "Odśwież",
  },

  examinations: {
    package: "pakiet",
    no_examinations: "Brak badań.",
    no_packages: "Brak pakietów.",
    no_services: "Brak badań oraz pakietów.",
    additional: "Badania dodatkowe",
    searched_examination: "Szukane badanie",
    searched_package: "Szukany pakiet",
    package_details: "Szczegóły pakietu",
    examinaiotn_details: "Szczegóły badania",
    search_results_for: "Wyniki wyszukiwania dla",
  },

  filters: {
    status: "Status",
    referral_number: "Numer zlecenia",
    patient: "Pacjent",
    doctor: "Lekarz",
    price: "Cena",
    commission: "Wynagrodzenie",
    date_of_issue: "Data wystawienia",
    cost: "Koszt badania",
    company_name: "Nazwa firmy",
    patient_discount_percent: "Procent rabatu",
  },

  patient_discount_percent: {
    zero: "0 %",
    five: "5 %",
    ten: "10 %",
    fifteen: "15 %",
    all: "Wszystkie",
  },

  layouts: {
    main_page: "Strona główna",
    faq: "FAQ",
    contact: "Kontakt",
    patient_info: "Informacje dla Pacjenta",
    about_us: "O nas",
    catalog: "Katalog badań",
    cart: "Koszyk",
    account: "Twoje konto",
    results: "Twoje badania i zlecenia",
    doctor_referral: "Zlecenie z ALAB Doctor",
    search: "Wyniki wyszukiwania",
    doctor: "Lekarz",
    patient: "Pacjent",
    discount: "LOREM IPSUM",
    discount_2: "-25",
    discount_3: "% DOLOR SIT AMET -",
    discount_4: "SPRAWDŹ",
    receive_results: "Odbierz wyniki",
    collection_points: "Punkty Pobrań",
    order_detail: "Szczegóły zamówienia",
    retry_payment: "Ponowna płatność",
    title: "ALAB w domu",
    registration: "Rejestracja",
    login: "Logowanie",
  },

  navigation: {
    catalog: "Kup badanie",
    exams: "Badania",
    packages: "Pakiety badań",
    collection_points: "Punkty Pobrań",
    receive_results: "Odbierz wyniki",
    promos: "Akcje profilaktyczne",
    blog: "Centrum Wiedzy",
    alab_sport: "ALAB sport",
  },

  medical_categories: {
    andrologist: "Androlog",
    allergist: "Alergolog",
    dermatologist: "Dermatolog",
    gynecologist: "Ginekolog",
    orthopedist: "Ortopeda",
    obstetrician: "Położnik",
    diabetologist: "Diabetolog",
    internist: "Internista",
    pediatrician: "Pediatra",
    psychologist: "Psycholog",
    dietician: "Dietetyk",
    cardiologist: "Kardiolog",
    urologist: "Urolog",
    endocrinologist: "Endokrynolog",
    family_doctor: "Lekarz rodzinny",
    nurse: "Pielęgniarka",
    venereologist: "Wenerolog",
    sports_medicine_physician: "Lekarz medycyny sportowej",
    physiatrist: "Fizjoterapeuta",
    coach: "Trener",
    main_category: "Kategoria lekarska",
    list: "Kategoria lekarska",
    all: "Wszystkie",
  },

  messages: {
    code: {
      resend: "Kod został wysłany ponownie.",
      resend_error: "Wystąpił błąd podczas wysyłania kodu.",
    },
    edit: {
      success: "Zmiany zostały zapisane.",
      failure: "Wystąpił błąd. Zmiany nie zostały zapisane.",
    },
    save: {
      success: "Zapisano pomyślnie.",
      failure: "Wystąpił błąd. Nie udało się zapisać.",
    },
    delete: {
      success: "Usunięto pomyślnie.",
      failure: "Wystąpił błąd. Nie udało się usunąć.",
    },
    sms: {
      success: "Sms został wysłany ponownie.",
      failure: "Wystąpił błąd. Sms nie został wysłany ponownie.",
    },
    send: {
      failure: "Wystąpił błąd. Nie udało się wysłać wiadomości.",
    },
    payment_token: {
      failure:
        "Wystąpił błąd. Spróbuj ponownie albo skontaktuj się z administratorem.",
    },
    permission: {
      failure: "Nie można wykonać.",
    },
    download: {
      failure: "Wystąpił błąd podczas pobierania pliku.",
      no_invoice: "Brak faktur z wybranego miesiąca.",
    },
    results: {
      failure: "Wystąpił błąd podczas pobierania wyników.",
    },
    error_code: {
      401: "Nieautoryzowany dostęp",
      403: "Nie posiadasz uprawnień do zasobu",
    },
  },

  not_found_page: {
    error: "Błąd",
    not_found: "Nie znaleziono strony",
    go_back: "Wróć na stronę główną",
  },

  pages: {
    account: "Ustawienia konta",
    history_orders: "Historia zamówień",
    my_settlements: "Moje rozliczenia",
    doctors: "Lekarze",
    referrals: "Zlecenia",
    invoices: "Faktury",
    your_referrals: "Twoje zamówienia",
  },

  patients: {
    add_kid: "Dodaj kolejne dziecko (3 miesiące - 17 lat)",
    add_adult: "Dodaj kolejnego dorosłego",
    edit_patient: "Edytuj wybranego Pacjenta",
    edit: "Edytuj Pacjenta",
    edit_data: "Edytuj dane Pacjenta",
    data: "Dane Pacjenta",
    no_patients: "Nie posiadasz jeszcze Pacjentów.",
    add_patient: "Dodaj Pacjenta",
    add_patient_to_be_able_to_assign_examinations:
      "Dodaj Pacjenta by móc przypisać mu badania",
    add_kid_to_account: "Dodaj dziecko do swojego konta",
    assign_selected_examination_to_a_patient:
      "Przypisz wybrane badanie do Pacjenta",
    up_to_5_patients_can_be_added: "Możesz dodać maksymalnie 5 Pacjentów",
    enter_date_of_birth: "Data urodzenia",
    assigned: "Przypisano",
    age_range: "Przedział wiekowy",
    kid: "Dziecko (3 miesiące - 17 lat)",
    adult: "Dorosły",
    remove_patient: "Usuń Pacjenta",
    sign_in_to_add_other_person_data:
      "Wprowadź dane Pacjenta, dla którego chcesz zakupić badania",
    you_added_max_number_of_people: "Dodałeś maksymalną liczbę osób",
    patient_in_system: "Pacjent znajduje się już w naszym systemie",
  },

  referral_status: {
    patient: {
      issued: "Nowe",
      paid: "Opłacone",
      canceled: "Anulowane",
      completed: "Zrealizowane",
    },
  },

  referrals: {
    enter_study_name: "Wpisz nazwę badania",
    referral: "Zlecenie",
    number_short: "nr",
    examinations: "badania",
    date_of_issue: "data wystawienia",
    date_of_test: "data wykonania badań",
    exact_price: "Dokładną cenę poznasz po wyborze Punktu Pobrań",
    doctor: "lekarz",
    doctorZone: "Strefa lekarza",
    number: "Zlecenie nr",
    issued_on: "Wystawiono dnia",
    see_results: "Zobacz wyniki badań",
    commissioned_examinations: "Zlecone badania",
    patient: "Pacjent",
    patientZone: "Strefa Pacjenta",
    phone_number: "Nr telefonu",
    pesel: "Pesel",
    payment: "Płatność",
    commission: "Twoje wynagrodzenie",
    add_or_choose_patient:
      "Uzupełnij dane nowego Pacjenta lub znajdź na liście swoich Pacjentów",
    issue_referrals: "Wystaw zlecenia",
    patient_list: "Lista Pacjentów",
    choose_patient: "Wybierz Pacjenta z listy",
    full_name: "Imię i nazwisko",
    search_patient: "Wpisz imię, nazwisko, pesel lub nr telefonu",
    send_sms_again: "Wyślij sms ponownie",
    edit_issued_referral: "Edytuj zlecone badania",
    issued_referral_text_part1:
      "Wystawiłeś zlecenie. Wysłaliśmy SMS do Pacjenta z instrukcją realizacji zlecenia.",
    issued_referral_text_part2:
      "Otrzymasz wynagrodzenie, kiedy Pacjent opłaci zlecenie.",
    referral_for_examination: "Zlecenie badań laboratoryjnych",
    referral_for_examination_text:
      "Wydrukowane zlecenie Twój Pacjent może zrealizować bezpośrednio w Punkcie Pobrań ALAB laboratoria. Płatności dokona lub online",
    print_referral: "Drukuj zlecenie",
    collection_facilities: "Punkty Pobrań",
    collection_facilities_text:
      "Sprawdź, w którym Punkcie Pobrań Twój Pacjent może zrealizować zlecenie",
    see_collection_facilities: "Zobacz Punkty Pobrań",
    examination_findings: "Wyniki badań",
    examination_findings_text:
      "Wyniki badań będą dostępne po zrealizowaniu zlecenia",
    unavailable: "Niedostępne",
    waiting_for_payment: "Oczekiwanie na płatność",
    waiting_for_commissions: "Oczekiwanie na wynagrodzenie",
    price_online_payment:
      "Cena przy płatności internetowej w serwisie ALAB Online.pl",
    price_facility_payment: "Cena przy płatności w Punkcie Pobrań Alab",
    commission_after_purchase:
      "Wynagrodzenie naliczy się po wykupieniu zlecenia przez Pacjenta",
    no_referrals: "Nie posiadasz wystawionych zleceń.",
    issue_referral: "Wystaw zlecenie na badanie",
    popular_examinations: "Najczęściej wybierane badania",
    package: "pakiet",
    added: "Dodano",
    number_of_referrals: "Ilość zleceń",
    average_number_of_referrals: "Śr. ilość zleceń",
    average_price_of_referral: "Śr. cena zlecenia",
    top_doctors: "Lekarze, którzy wystawili zlecenia na największą sumę:",
    your_facility: "Twój Punkt Pobrań",
    choose_examinations: "Dobierz badania",
    additionally_examinations:
      "Jeżeli chcesz, możesz dodatkowo wykonać inne badania",
    ordered_examinations: "Zlecone badania",
    change: "Zmień dane i wyślij zlecenie ponownie",
    dates: "Daty",
    waiting_time_working_days: "Czas oczekiwania (dni robocze)",
    status: "Status",
    statuses: {
      ready: "Gotowe",
      in_progress: "W realizacji",
    },

    tabs: {
      packages: "Pakiety",
      examinations: "Badania",
    },

    table: {
      id: "Id zlecenia",
      date: "Data",
      full_name: "Imię i nazwisko",
      identity_card: "Nr dowodu",
      status: "Status",
      phone: "Telefon",
      number_of_examinations: "Ilość badań",
      commission: "Wynagrodzenie",
      show: "Zobacz zlecenie",
      show_patient: "Zobacz Pacjenta",
      code: "Kod pocztowy",
      city: "Miasto",
      street: "Ulica",
      cost: "Koszt badania",
      pesel: "Pesel",
      patient_discount_percent: "Procent rabatu",
      patient_discount: "Rabat",
    },
    from_doctor: "Zamówienie z ALAB Doctor nr.",
  },

  results: {
    download_pdf: "Pobierz wyniki w PDF",
    back_to_referral: "Powrót do zamówienia",
    choose_examination: "Wybierz badanie, żeby wyświetlić wyniki",
    lab: "Punkt Pobrań",
    date_of_result_collection: "Data pobrania wyniku",
    referral_number: "Numer zamówienia",
    examinations_for: "Badania dla",
    date_of_issue: "Data zamówienia",
    doctor: "Zlecający",
    doctor_referral: "Zlecenie z ALAB Doctor",
    alabsport_referral: "Zamówienie z alab sport",
    date_of_collection: "Data wykonania badań",
    home_visit_address: "Adres wizyty domowej",
    collection_point_service: "Usługa w Punkcie Pobrań",
    order_no: "Zamówienie nr",
    sensitive:
      "Twoje zamówienie zawiera wrażliwe badania. Odbiór wyników możliwy jest tylko w punkcie Alab.",
  },

  select_time: {
    current_month: "Bieżący miesiąc",
    previous_month: "Poprzedni miesiąc",
    last_90_days: "Ostatnie 90 dni",
    last_6_months: "Ostatnie 6 miesięcy",
    current_year: "Bieżący rok",
    selected_from: "Wybierz z kalendarza",
  },

  titles: {
    your_finances: "Twoje finanse",
    your_stats: "Twoje statystyki",
    stats: "Statystyki",
    top_doctors: "Top lekarze",
    block_account: "Twoje konto zostało zablokowane",
  },

  your_finances: {
    to_pay: "Do wypłaty",
  },

  your_referrals: {
    get_results_by_barcode: "Odbierz wyniki wpisując otrzymany kod kreskowy",
    waiting_time: "Czas oczekiwania",
    barcode: "Kod kreskowy",
    see_results: "Zobacz wyniki badań",
    check_results: "Sprawdź wyniki badań",
    pay: "Opłać badania",
    see_summary: "Zobacz podsumowanie",
    just_paid: "Opłaciłeś już to zlecenie.",
    go_to_facility: "Udaj się do Punktu Pobrań, żeby wykonać badania.",
    expired: "Zlecenie wygasło",
    expired_details:
      "Zlecenie z systemu ALAB Doctor można opłacić w ciągu 3 miesięcy od wystawienia",
    canceled: "Zlecenie anulowane",
    canceled_details:
      "Zlecenie zostało anulowane przez osobę zlecającą, nie może być opłacone i zrealizowane",
    archive: "Archiwum",
  },
  documents: {
    terms: "Regulamin serwisu",
    privacy_policy: "Polityka prywatności",
  },

  your_settlements: {
    user_full_name: "Imię i nazwisko",
    address: "Adres",
    billing_data: "Dane do faktury",
    edit_billing_data: "Edytuj dane do faktury",
    my_settlements: "Moje rozliczenia",
    verify_data_title: "Zweryfikuj swoje dane",
    verify_data_text:
      "Podaj dane do rozliczeń, aby móc rozliczyć wynagrodzenie od wystawionych zleceń",
    company_data_title: "Dane firmy",
    company_name: "Nazwa firmy",
    nip: "NIP",
    free_from_vat_title: "Czy jesteś zwolniony z VAT?",
    free_from_vat:
      "Oświadczam, iż przysługuje mi prawo do zwolnienia podmiotowego przewidzianego w art. 113 ust. 1 ustawy o VAT",
    address_title: "Adres",
    street: "Ulica",
    house_number: "Numer budynku",
    flat_number: "Numer lokalu",
    postal_code: "Kod pocztowy",
    city: "Miejsowość",
    account_number_title: "Numer rachunku bankowego",
    account_number: "Numer rachunku bankowego",
    verify_account: "Zweryfikuj konto",
    your_invoices: "Twoje faktury",

    checkboxes: {
      check_all: "Zaznacz wszystkie",
      agree_terms: "Oświadczam, iż przeczytałem i akceptuję",
      terms: "regulamin",
      marketing:
        "Wyrażam zgodę na przesyłanie informacji handlowych i marketingowych, za pośrednictwem środków komunikacji elektronicznej.",
      agree3:
        "Donec dolor mi, interdum vel arcu vel, gravida sollicitudin est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed accumsan dui et sollicitudin porttitor",
      agree4:
        "Donec sed lacus leo. Fusce fermentum sem sed odio luctus, eu sollicitudin massa elementum. Maecenas pellentesque ligula libero, vel condimentum sem lobortis et",
    },

    invoice: {
      date: "Data",
      number: "Numer faktury",
      amount: "Kwota",
      download: "Pobierz fakturę",
      i_want_to_receive_an_invoice: "Chcę otrzymać fakturę",
      what_entity: "Na jaki podmiot będzie wystawiona faktura?",
      person: "Osoba fizyczna",
      company_or_institution: "Firma lub instytucja",
      company_name: "Nazwa firmy",
      additional_info:
        "Faktura zostanie wystawiona do 15 dnia następnego miesiąca. Otrzymasz wiadomość e-mail, kiedy faktura będzie gotowa do pobrania w Twoim koncie Pacjenta",
    },
  },

  your_stats: {
    issued_referrals: "Wystawione zlecenia",
    paid_referrals: "Opłacone zlecenia",
    completed_referrals: "Zrealizowane zlecenia",
    canceled_referrals: "Anulowane zlecenia",
    commission: "Wynagrodzenie",
    cost: "Koszt badań",
  },
  payment: {
    made: "Platność zrealizowana.",
    canceled: "Płatność anulowana.",
    advance: "Oczekiwanie na potwierdzenie płatności.",
    returned: "Płatność zwrócona.",
    go_to_dashboard: "Przejdź do panelu głównego",
    retry_payment: "Ponów płatność",
    something_went_wrong: "Coś poszło nie tak",
    try_again: "Spróbuj ponownie",
    contact_us: "Spróbuj dokonać płatności ponownie lub skontaktuj się z nami",
    payment_error: "Przepraszamy, wystąpił nieoczekiwany błąd płatności.",
    order_paid: "Twoje zamówienie zostało opłacone.",
    order_waiting_paid: "Twoje zamówienie oczekuje na opłacenie.",
    thanks: "Dziękujemy!",
    realize:
      "Aby zrealizować zamówienie, odwiedź wybrany Punkt Pobrań i podaj numer zamówienia, który otrzymasz SMS-em oraz e-mailem. Zamówienie należy zrealizować w ciągu <strong>60 dni</strong> od daty zakupu.",
    our_consultant:
      "Twoje zamówienie zostało opłacone. Nasz konsultant przyjedzie pod wskazany przez Ciebie adres w terminie:",
    transaction_code: "Kod transakcji",
    facility: "Punkt Pobrań",
    monday_friday: "Pon - Pt",
    saturday: "Sob",
    fetch_status_error_msg: "Wystąpił problem z pobieraniem statusu zamówienia",
    check_status_again: "Sprawdź status ponownie",
    buy_additional_examinations: "Dokup dodatkowe badania",
  },

  home: {
    find_a_examination_that_interests_you:
      "Znajdź badanie, które Cię interesuje",
    examination_packages: "Pakiety badań",
    the_most_popular_packages: "Najczęściej wybierane pakiety badań",
    other_packages: "Pozostałe pakiety badań",
    examinations: "Badania",
    the_most_popular_examinations: "Najczęściej wybierane badania",
    other_examinations: "Pozostałe badania",
    examinations_categories: "Kategorie badań",
    packages_categories: "Kategorie pakietów badań",
    what_examination_are_you_looking_for: "Jakiego badania szukasz?",
    create_own_package: "Stwórz swój pakiet badań ze zniżką",
  },

  own_packages: {
    own_package: "Własny zestaw badań",
    choose_examination_to_get_discount:
      "Wybierz od 5 do 15 badań i kup je ze zniżką do&nbsp;-25%",
    own_packages_alert:
      "Własny zestaw badań można stworzyć wybierając badania z poniższej listy.",
    primary_examinations: "Badania podstawowe",
    profiled_examinations: "Badania sprofilowane",
    your_own_package: "Własny zestaw badań",
    your_own_package_short: "Własny zestaw",
    you_got_the_maximum_discount:
      "W zestawie znajduje się maksymalna liczba badań. Osiągnięto rabat 25%",
    add_at_least: "Dodaj co najmniej",
    examinaions_to_receive_a_discount: "badania, aby otrzymać zniżkę",
    create_own_package: "Stwórz swój pakiet badań ze zniżką",
    own_package_info:
      "To badanie wchodzi w skład pakietów własnych - które możesz stworzyć sam ze zniżką",
  },

  collection_points: {
    filters: {
      is_covid_private: "Przystosowany do COVID19",
      is_children: "Przyjazny dzieciom",
      is_40_plus: "Profilaktyka 40+",
      is_ginecology: "Ginekologiczny",
      is_sport: "ALAB Sport",
      is_swab: "Pobór wymazów",
      is_parking: "Przy laboratorium",
      open_sunday: "Otwarty w niedzielę",
      open_saturday: "Otwarty w sobotę",
    },
    additional_info: "Dodatkowe informacje",
    additional_open: "Dodatkowe godziny otwarcia",
    additional_close: "Punkt NIECZYNNY",
    invalid_home_service_for_kid:
      "Zwróć uwagę, że część Punktów Pobrań oraz mobilna usługa pielęgniarska (ALAB w domu) nie umożliwiają wykonania pobrań u dzieci w wieku poniżej 3 lat.",
  },
  footer: {
    alab_group: "Grupa ALAB",
    usefull_links: "Przydatne linki",
    payment_description:
      "ALAB laboratoria sp. z o.o. z siedzibą w Warszawie przy ul. Stępińskiej 22/30, 00-739 Warszawa, zarejestrowana w Rejestrze Przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy KRS, pod nr KRS 0000040890, NIP: 522-000-02-17, REGON: 008105218, kapitał zakładowy: 1 002 000 zł., posiadająca status dużego przedsiębiorcy.",
    e_shop: "E-sklep",
    online_results: "Wyniki online",
    covid_diagnostics: "Diagnostyka COVID-19",
    patient_information: "Informaca dla Pacjenta",
    patient_forms: "Formularze dla Pacjenta",
    offer_for_Employers: "Oferta dla Pracodawców",
    signalist: "Sygnalista",
    tax_strategy: "Strategia podatkowa",
    career: "Kariera/ Staże",
    for_doctor: "Dla lekarza",
    survey: "Ankieta",
    terms_of_service: "Regulamin",
    privacy_policy: "Polityka prywatności",
    returns: "Reklamacje",
    withdrawals: "Odstąpienia",
    cookies_panel: "Ustawienia plików cookie",
    about_us: "O nas",
    receive_results: "Odbierz wyniki",
    collection_points: "Punkty Pobrań",
    catalog: "Katalog badań",
    contact: "Kontakt",
    patient_info: "Informacje dla Pacjenta",
    faq: "FAQ",
    rezerwacja: "Rezerwacja",
  },
  discount_code: {
    realize: "Aktywuj",
    add_code: "Dodaj kod rabatowy",
    type_code: "Wpisz swój kod",
    code: "Kod rabatowy",
    successful_added: "Kod został aktywowany",
  },
  account_migration: {
    type: {
      info: "Zauważyliśmy, że masz Konto Pacjenta zarejestrowane w starym systemie. Czy chcesz zmigrować z niego swoje dane?",
      migrate: "Migruj",
      create_new_account: "Załóż nowe konto",
    },
    phone_verification: {
      info: "Aby kontynuować, zweryfikuj swój numer telefonu.",
      verify: "Zweryfikuj",
    },
    reset_password: "Zresetuj hasło",
    terms_of_service: "Regulamin",
    cceptance_of_terms_of_service_is_required:
      "Akceptacja regulaminu jest konieczna do dalszego użytkowania konta pacjenta systemie alab.pl",
  },
};
